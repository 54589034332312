module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":704,"disableBgImageOnAlpha":true,"linkImagesToOriginal":false,"backgroundColor":"transparent","showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/@platformos/gatsby-theme-platformos-docskit/gatsby-browser.js'),
      options: {"plugins":[],"useLegacyUrls":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DOB Tertius CofO User Guide","short_name":"Tertius CofO User Guide","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"/Users/nemvalid/www/docskit-gatsby/docskit-dob/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"66c67c5dad844ba249ea4b36bcf74f3f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
